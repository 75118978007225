<template>
  <div style="display: flex">
    <el-select
      v-model="part1"
      placeholder="Уровень 1"
      clearable
      size="mini"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.current_part_id')"
      @visible-change="$event ? loadParts() : null"
      @change="selectChanged"
    >
      <el-option
        v-for="filteredPart in part1_list"
        :key="filteredPart.id"
        :label="filteredPart.part_1"
        :value="filteredPart.id"
      ></el-option>
    </el-select>

    <el-select
      v-model="part2"
      placeholder="Уровень 2"
      clearable
      size="mini"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.current_part_id')"
      @visible-change="$event ? loadParts() : null"
      @change="selectChanged"
    >
      <el-option
        v-for="filteredPart in part2_list"
        :key="filteredPart.id"
        :label="filteredPart.part_2"
        :value="filteredPart.id"
      ></el-option>
    </el-select>

    <el-select
      v-model="part3"
      placeholder="Уровень 3"
      clearable
      size="mini"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.current_part_id')"
      @visible-change="$event ? loadParts() : null"
      @change="selectChanged"
    >
      <el-option
        v-for="filteredPart in part3_list"
        :key="filteredPart.id"
        :label="filteredPart.part_3"
        :value="filteredPart.id"
      ></el-option>
    </el-select>
  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import requestSender from "@/api/base/requestSender";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-scale-part-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {
      part1: null,
      part2: null,
      part3: null,

      parts: [],
      filteredParts: [],

      part1_list: [],
      part2_list: [],
      part3_list: [],
    }
  },
  mounted() {
    if( this.scope.row.scale && this.scope.row.scale.current_scale_part ){
      this.filteredParts = [this.scope.row.scale.current_scale_part];
      this.part1 = this.scope.row.scale.current_scale_part.id;
      this.part2 = this.scope.row.scale.current_scale_part.id;
      this.part3 = this.scope.row.scale.current_scale_part.id;

      this.preparePartLists();
    }
  },
  methods: {
    loadParts() {
      if( this.parts.length ){
        return;
      }
      return requestSender('get', 'scale/get-parts-for-card-element', {
        id: this.scope.row?.id,
      })
        .then(data => {
          this.parts = data.scale_parts;
          this.filterPartLists();
        })
    },

    async selectChanged(chosenPartId){
      await this.loadParts();
      if( this.part1 && this.part2 && this.part3 ){
        this.saveElementPropChange(this.scope.row, 'scale', {current_part_id:chosenPartId})
      }
      this.filterPartLists();
    },

    filterPartLists(){
      this.filteredParts = this.parts;
      if( this.part1 ){
        let text = this.parts.find(p => p.id === this.part1).part_1;
        this.filteredParts = this.parts.filter(p => p.part_1 === text);
      }
      if( this.part2 ){
        let text = this.parts.find(p => p.id === this.part2).part_2;
        this.filteredParts = this.filteredParts.filter(p => p.part_2 === text);
      }
      if( this.part3 ){
        let text = this.parts.find(p => p.id === this.part3).part_3;
        this.filteredParts = this.filteredParts.filter(p => p.part_3 === text);
      }

      this.preparePartLists();
    },

    preparePartLists(){
      // Removing duplicates in lists
      this.part1_list = this.filteredParts.map(({ part_1 }) => part_1);
      this.part1_list = this.filteredParts.filter(({ part_1 }, index) => !this.part1_list.includes(part_1, index + 1));
      this.part2_list = this.filteredParts.map(({ part_2 }) => part_2);
      this.part2_list = this.filteredParts.filter(({ part_2 }, index) => !this.part2_list.includes(part_2, index + 1));
      this.part3_list = this.filteredParts.map(({ part_3 }) => part_3);
      this.part3_list = this.filteredParts.filter(({ part_3 }, index) => !this.part3_list.includes(part_3, index + 1));
    }
  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

</style>
